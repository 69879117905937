.DashboardPage {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.DashboardPage-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DashboardPage-field {
  padding: .5rem;
  line-height: 1.5rem;
  font-size: .875rem;
  border-radius: 0.375rem;
  appearance: none;
  border: 0;
  margin-bottom: 1rem;
}

.DashboardPage-field:focus {
  outline: 0 solid transparent;
}

.DashboardPage-button {
  padding: .5rem .75rem;
  background-color: rgb(59 130 246);
  color: white;
  border-radius: .375rem;
  outline: 0;
  border:0;
}

.DashboardPage-button:hover {
  cursor: pointer;
  color: rgb(198, 218, 250)
}

.DashboardPage-button:disabled {
  background-color: rgb(22, 78, 99);
  color: rgb(8, 145, 178);
  cursor: not-allowed;
}

.DashboardPage-error {
  color: rgb(239 68 68);
}

.DashboardPage-select {
  width: 100%;
}

.Dashboard-datetime .form-control {
  padding: .5rem;
  line-height: 1.5rem;
  font-size: .875rem;
  border-radius: 0.375rem;
  appearance: none;
  border: 0;
  margin-bottom: 0;
}

.Dashboard-datetime {
  color: black;
  padding: 0;
  margin-bottom: 1rem;
}

.Dashboard-datetime .form-control:focus {
  outline: 0 solid transparent;
}
