.LoginPage {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  color: white;
}

.LoginPage-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.LoginPage-field {
  padding: .5rem;
  line-height: 1.5rem;
  font-size: .875rem;
  border-radius: 0.375rem;
  appearance: none;
  border: 0;
  margin-bottom: 1rem;
}

.LoginPage-field:focus {
  outline: 0 solid transparent;
}

.LoginPage-button {
  padding: .5rem .75rem;
  background-color: rgb(59 130 246);
  color: white;
  border-radius: .375rem;
  outline: 0;
  border:0;
}

.LoginPage-button:hover {
  cursor: pointer;
  color: rgb(198, 218, 250)
}

.LoginPage-error {
  color: rgb(239 68 68);
}
