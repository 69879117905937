.CreateScenarioForm-form {
  display: grid;
  grid-template-columns: 2;
  grid-template-rows: 4;
  column-gap: 2rem;
  row-gap: .75rem;
}

.CreateScenarioForm-grid-name {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
}

.CreateScenarioForm-grid-type {
  grid-column: 2;
  grid-row: 1;
}

.CreateScenarioForm-grid-scenario_datetime {
  grid-column: 2;
  grid-row: 2;
  display:flex;
  flex-direction: column;
}

.CreateScenarioForm-grid-event_datetime {
  grid-column: 1;
  grid-row: 2;
  display:flex;
  flex-direction: column;
}

.CreateScenarioForm-grid-checkbox {
  grid-column: 1;
  grid-row: 3;
}

.CreateScenarioForm-grid-submit {
  grid-column: 1;
  grid-row: 4;
}

.CreateScenarioForm-section-label {
  font-weight: bold;
}

.CreateScenarioForm-small {
  font-size: 12px;
  padding: 0;
  margin: 0;
  margin-top: -.75rem;
  margin-bottom: .75rem;
}