.Scenario {
  padding: .5rem;
  border-radius: 0.375rem;
  border: 1px solid grey;
}

.Scenario-actions {
  padding: 0.2rem;
}

.Scenario-label {
  font-weight: bold;
}

.Scenario-checkbox-label {
  margin-bottom: 16px;
  display: inline-block;
}
